import { useCallback, useEffect, useState } from "react";
import { Certificate } from "../components/CertificateCard";

const ENDPOINT =
  process.env.NODE_ENV === "production"
    ? window.location.origin
    : "http://localhost:4000";

export default function useCertificates() {
  const [data, setData] = useState<Certificate[]>([]);

  const fetchData = useCallback(() => {
    fetch(ENDPOINT + "/api" + window.location.pathname).then((response) =>
      response.json().then((responseData) => {
        (responseData as Certificate[]).forEach((item) => {
          item.timestamp = new Date(item.timestamp);

          if (item.success) {
            item.validFrom = new Date(item.validFrom);
            item.validTo = new Date(item.validTo);
          }
        });

        setData(responseData);
      }),
    );
  }, [setData]);

  useEffect(() => {
    const interval = setInterval(fetchData, 1 * 60 * 1000);
    fetchData();

    return () => {
      clearInterval(interval);
    };
  }, [fetchData]);

  return data;
}
