import React from "react";
import CertificateCard from "../CertificateCard";
import type { Certificate } from "../CertificateCard";
import useCertificates from "../../hooks/useCertificates";
import useTime from "../../hooks/useTime";
import style from "./style.css";

export default function App(): JSX.Element {
  const certificates = useCertificates();
  const time = useTime();

  function createKey(certificate: Certificate): string {
    return `${certificate.host}@${certificate.timestamp.toISOString()}`;
  }

  const certificateComponents = certificates
    .sort((c1, c2) => {
      if (c1.success && c2.success) {
        return c1.validTo.getTime() - c2.validTo.getTime();
      }
      if (c1.host < c2.host) {
        return -1;
      }
      if (c1.host > c2.host) {
        return 1;
      }
      return 0;
    })
    .map((certificate) => (
      <CertificateCard
        key={createKey(certificate)}
        currentTime={time}
        {...certificate}
      />
    ));

  return (
    <div className={style.app}>
      <div className={style.head}>
        <h1>CertWatch</h1>
      </div>

      <div className={style.list}>{...certificateComponents}</div>
    </div>
  );
}
