import { useEffect, useState } from "react";

export default function useTime() {
  const [time, setTime] = useState(() => new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [setTime]);

  return time;
}
