import React from "react";
import style from "./style.css";

export type Certificate =
  | {
      success: true;
      valid: boolean;
      host: string;
      validFrom: Date;
      validTo: Date;
      timestamp: Date;
    }
  | {
      success: false;
      valid: false;
      host: string;
      timestamp: Date;
    };

export type CertificateProps = Certificate & { currentTime: Date };

function getHumanReadableStatus(certificate: Certificate): string {
  if (!certificate.success) return "unknown";

  return certificate.valid ? "valid" : "expired";
}

function timeString(diff: number) {
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  diff -= days * (1000 * 60 * 60 * 24);

  const hours = Math.floor(diff / (1000 * 60 * 60));
  diff -= hours * (1000 * 60 * 60);

  const minutes = Math.floor(diff / (1000 * 60));
  diff -= minutes * (1000 * 60);

  const seconds = Math.floor(diff / 1000);
  diff -= seconds * 1000;

  function pad(num: number) {
    return num.toString().padStart(2, "0");
  }

  const pluralDays = days !== 1;

  const noDays = days === 0;

  return (
    <>
      {noDays ? null : (
        <span className={style.large}>
          {days} day{pluralDays ? "s" : ""}{" "}
        </span>
      )}
      <br />
      <span className={noDays ? style.large : style.medium}>
        {pad(hours)}:{pad(minutes)}:{pad(seconds)}
      </span>
    </>
  );
}

export default function CertificateCard(props: CertificateProps): JSX.Element {
  if (!props.success) {
    return (
      <div className={`${style.cert} ${style.unknown}`}>
        <ul>
          <li>Domain: {props.host}</li>
          <li>Status: unknown</li>
        </ul>
      </div>
    );
  }

  const timeLeft = props.validTo.getTime() - props.currentTime.getTime();
  const valid = timeLeft > 0;
  const closeToExpire = timeLeft <= 7 * 24 * 60 * 60 * 1000;
  const veryCloseToExpire = timeLeft <= 1 * 24 * 60 * 60 * 1000;

  let className = style.cert + " ";

  if (!valid) className += style.expired;
  else if (veryCloseToExpire) className += style.veryClose;
  else if (closeToExpire) className += style.close;
  else className += style.valid;

  return (
    <div className={className}>
      <span className={style.countdown}>
        {valid ? timeString(timeLeft) : "Expired"}
      </span>
      <span className={style.hostname}>{props.host}</span>
      <span className={style.expiry}>
        Valid until {props.validTo.toLocaleString("de-DE")}
      </span>
    </div>
  );
}
