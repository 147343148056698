import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";

import styles from "./style.css";

const rootContainer = document.createElement("div");
rootContainer.id = styles.root;
document.body.appendChild(rootContainer);

const root = createRoot(rootContainer);
root.render(<App />);
