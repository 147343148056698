// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,\nbody,\n#Vp5B0KOqU5p4JBGYpgRj {\n  width: 100%;\n  height: 100%;\n  min-height: 100%;\n\n  overflow: hidden;\n\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  color: white;\n  background-color: rgb(50, 50, 50);\n\n  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;\n}\n", "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;;;EAGE,WAAW;EACX,YAAY;EACZ,gBAAgB;;EAEhB,gBAAgB;;EAEhB,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,YAAY;EACZ,iCAAiC;;EAEjC,mJAAmJ;AACrJ","sourcesContent":["html,\nbody,\n#root {\n  width: 100%;\n  height: 100%;\n  min-height: 100%;\n\n  overflow: hidden;\n\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  color: white;\n  background-color: rgb(50, 50, 50);\n\n  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Vp5B0KOqU5p4JBGYpgRj"
};
export default ___CSS_LOADER_EXPORT___;
